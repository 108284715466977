<template>
  <div class="container-box">
		<div class="title">
      <div>{{title}}</div>
    </div>
		<div class="row">
				<div ref="deviceutilizationrate" class="chartBox_d"></div>
		</div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import API from "../../plugins/axiosInstance"

export default {
  name: 'lightBox',
  data () {
    return {
      title: '全场设备利用率',
      persent:0,
      deviceInfo:{
        run:0,
        len:0
      }
    }
  },
  methods: {
    initEchart(myCharts) {
      const that = this
      let run = that.deviceInfo.run
      let all = that.deviceInfo.len
      that.mycharts = echarts.init(that.$refs[myCharts]);
      const gaugeData = [
        {
          value: [run,all],
          name: '利用率',
          title: {
            offsetCenter: ['0%', '20%'],
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '-15%'],
          },
        },
      ]
      that.mycharts.setOption({
        grid: {
            left: '0%',
            right: '3%',
            bottom: '15%',
            top:'20%',
            containLabel: true
        },
        series: [
          {
            type: 'gauge',
            radius: 100,
            startAngle: 270,
            endAngle: -0,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              // 多组数据时进度条是否重叠
              overlap: false,
              // width: 20,
              roundCap: true,
              clip: false,

              itemStyle: {
                borderWidth: 0,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#1f8cf1', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#211c87', // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            axisLine: {
              // 是否显示仪表盘轴线
              show: false,
              lineStyle: {
                // 仪表盘轴线宽度也要设置,否则只设置进度条宽度就不生效
                width: 10,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              show: false,
            },
            data: gaugeData,
            title: {
              fontSize: 16,
              color: '#fff',
            },
            detail: {
              fontSize: 26,
              color: '#fff',
              fontFamily: '微软雅黑',
              formatter: '{value}%',
            },
          },
        ]
      });
    },
    getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        that.deviceInfo.len = res.data.length
        that.deviceInfo.outline = res.data.filter(item => item.status == 0).length
        that.deviceInfo.run = res.data.filter(item => item.status == 1).length
        that.deviceInfo.check = res.data.filter(item => item.status == 2).length
        that.deviceInfo.unable = res.data.filter(item => item.status == 3).length
        that.persent =  that.deviceInfo.run / that.deviceInfo.len
      });
    },
  },
	mounted(){
    const that = this
    that.getLightBoxData()
    setTimeout(() => {that.initEchart("deviceutilizationrate")}, 1000)
    setInterval(() => {
      that.getLightBoxData()
    },5000)
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #2e6aa0;
}
.status-2 {
  background-color: #37c59f;
}
.status-3 {
  background-color: #f3df4c;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.chartBox_d {
  width: 100%;
  height: 250px;
}
@media screen and (max-width:1600px) {
  .chartBox_d {
    height: 225px;
    overflow: hidden;
    margin-top: -25px;
  }
}
</style>