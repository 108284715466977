<template>
  <div class="container-box">
		<div class="title">
      <div>{{title}}</div>
      <div>
        <ul>
          <li><span class="status-1"></span>A相电压</li>
          <li><span class="status-2"></span>B相电压</li>
          <li><span class="status-3"></span>B相电压</li>
        </ul>
      </div>
    </div>
		<div class="row">
				<div id="currentstate" class="chartBox_d"></div>
		</div>
  </div>
</template>

<script>
//import * as echarts from 'echarts'
// import echarts from 'echarts'
export default {
  name: 'lightBox',
  data () {
    return {
      title: '灯箱电流状态'      
    }
  },
	mounted(){
    let that = this
    setTimeout(() => {that.drawLine()}, 500)
  },
  methods: {
   //接口请求获取的数据
    drawLine(){
    // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('currentstate'))
    // 绘制图表
      myChart.setOption({
      // title: {
      //   text: '折线图堆叠'
      // },
        tooltip: {
          trigger: 'axis',
					formatter: function(params) {
						console.log(params)
						var result = ''
						var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#2e6aa0"></span>'
						var dotHtml2 = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#37c59f"></span>'
						var dotHtml3 = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#f3df4c"></span>'
						result += params[0].axisValue + "</br>" + dotHtml +'A相电流：' + params[0].data+ "</br>" + dotHtml2 +'B相电流：' + params[1].data+ "</br>" + dotHtml3 +'C相电流：' + params[2].data
						return result
					}
        },
      // legend: {
      //     data: ['邮件营销', '联盟广告', '视频广告' ]
      // },
        grid: {
            left: '0%',
            right: '3%',
            bottom: '15%',
            top:'20%',
            containLabel: true
        },
      // toolbox: {
      //     feature: {
      //         saveAsImage: {}
      //     }
      // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            show: true,
            textStyle: {
                color: '#fff'
            }
          }
        },
        yAxis: {
          // 去掉轴多出来的-
          axisTick: {
            show: false
          },
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed' //内容区域修改为虚线
            }
          },
          show: true,
          fontSize: 10,
          axisLabel: {
            show: true,
            textStyle: {
                color: '#fff'
            }
          }
          // 去掉Y轴坐标轴
        },
        series: [
          {
            name: '邮件营销',
						type: 'line',
            smooth: true,
						//stack: '总量',
						data: [120, 132, 101, 134, 90, 230, 210],
						lineStyle: {
							color:'#2e6aa0'
						},
						itemStyle:{//折线拐点标志的样式
							borderColor:"#2e6aa0",//拐点的边框颜色
							borderWidth:1,
							opacity:0
						}
          },
          {
						name: '联盟广告',
						type: 'line',
            smooth: true,
						//stack: '总量',
						data: [220, 182, 191, 234, 290, 330, 310],
						lineStyle: {
							color:'#37c59f'
						},
						itemStyle:{//折线拐点标志的样式
							borderColor:"#37c59f",//拐点的边框颜色
							borderWidth:1,
							opacity:0
						}
          },
          {
						name: '视频广告',
						type: 'line',
            smooth: true,
						//stack: '总量',
						data: [150, 232, 201, 154, 190, 330, 410],
						lineStyle: {
							color:'#f3df4c'
						},
						itemStyle:{//折线拐点标志的样式
							borderColor:"#f3df4c",//拐点的边框颜色
							borderWidth:1,
							opacity:0
						}
          }
        ]
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #2e6aa0;
}
.status-2 {
  background-color: #37c59f;
}
.status-3 {
  background-color: #f3df4c;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.chartBox_d {
    width: 100%;
    height: 250px;
  }
  
@media screen and (max-width:1600px) {
  .chartBox_d {
    height: 225px;
    /*overflow: hidden;*/
    margin-top: -15px;
  }
}
@media screen and (max-width:1440px) {
  .title {
    font-size: 14px;
  }
  .title>div>ul>li {
    margin: 0 5px;
  }
}

@media screen and (max-width:820px) {
  .title {
    font-size: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  .title>div>ul>li {
    margin: 0 5px;
  }
  .chartBox_d {
    width: 100%;
    height: 200px;
  }
}
</style>
