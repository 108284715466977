<template>
  <div class="container-box">
		<div class="title">
      <div>{{title}}</div>
      <!-- <div>
        <ul>
          <li><span class="status-1"></span>A相电压</li>
          <li><span class="status-2"></span>B相电压</li>
          <li><span class="status-3"></span>B相电压</li>
        </ul>
      </div> -->
    </div>
		<div class="row">
				<div id="statedistribution" class="chartBox_d"></div>
		</div>
  </div>
</template>

<script>
import API from "../../plugins/axiosInstance"
// import echarts from 'echarts'
export default {
  name: 'lightBox',
  data () {
    return {
      title: '设备状态分布',
      deviceInfo:{
        run:0,
        len:0,
        unable:0
      }   
    }
  },
	mounted(){
    let that = this
    that.getLightBoxData()
    setTimeout(() => {that.drawLine()}, 1000)
    setInterval(() => {
      that.getLightBoxData()
    },5000)
  },
  methods: {
   //接口请求获取的数据
   getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        that.deviceInfo.len = res.data.length
        that.deviceInfo.outline = res.data.filter(item => item.status == 0).length
        that.deviceInfo.run = res.data.filter(item => item.status == 1).length
        that.deviceInfo.check = res.data.filter(item => item.status == 2).length
        that.deviceInfo.unable = res.data.filter(item => item.status == 3).length
        that.persent =  that.deviceInfo.run / that.deviceInfo.len
        //console.log(that.deviceInfo.run)
        
      });
    },
    drawLine(){
    // 基于准备好的dom，初始化echarts实例
    const that = this
      let myChart = that.$echarts.init(document.getElementById('statedistribution'))
    // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          top: 'bottom',
          color:"#fff",
          textStyle: {
            // 图例文字的样式
            color:'#b1b5ce',
            fontSize: 10
          },
          data: ['运行数量', '离线数量', '未启用数量']
        },
        series: [
          {
            name: '设备状态分布',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: parseInt(that.deviceInfo.run), name: '运行数量'},
              {value: parseInt(that.deviceInfo.outline), name: '离线数量'},
              {value: parseInt(that.deviceInfo.unable), name: '未启用数量'},
            ]
          }
        ]
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #2e6aa0;
}
.status-2 {
  background-color: #37c59f;
}
.status-3 {
  background-color: #f3df4c;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.chartBox_d {
  width: 100%;
  height: 250px;
}
@media screen and (max-width:1600px) {
  .chartBox_d {
    height: 225px;
    overflow: hidden;
    margin-top: -25px;
  }
}
</style>
