<template>
  <div class="container-box">
		<div class="title">{{title}}</div>
		<div class="row">
			<div class="col-4">
				<div class="coltitle">室外温度</div>
				<div class="colcontent">32<span class="unit">℃</span></div>
			</div>
			<div class="col-4">
				<div class="coltitle">光照</div>
				<div class="colcontent">54<span class="unit">lux</span></div>
			</div>
			<div class="col-4">
				<div class="coltitle">气压</div>
				<div class="colcontent">
					54<span class="unit">kpa</span>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<div class="coltitle">紫外线指数</div>
				<div class="colcontent">
					54<span class="unit"></span>
				</div>
			</div>
			<div class="col-4">
				<div class="coltitle">相对湿度</div>
				<div class="colcontent">
					54<span class="unit">%</span>
				</div>
			</div>
			<div class="col-4">
				<div class="coltitle">PM2.5</div>
				<div class="colcontent">
					54<span class="unit">ug/cm<sup>3</sup></span>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'lightBox',
  data () {
    return {
      title: '室外气象环境监控',
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.row {
	padding-bottom: 30px;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 10px;
}
.coltitle {
	color: #FFF;
	font-size: 14px;
}
.colcontent {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 32px;
	font-weight: 700;
}
.unit {
	font-size: 14px;
	margin-left: 5px;
	padding-top: 12px;
}
@media screen and (max-width:1600px) {
  .colcontent {
    height: 40px;
  }
}
</style>
