<script>
import { RouterView } from 'vue-router'
export default {
  components:[
    RouterView
  ]
}
</script>

<template>
  <div id="app" v-wechat-title="$route.meta.title">
    <RouterView />
  </div>
</template>

<style scoped>
*{ padding: 0; margin: 0; }
#app {
  width: 100%;
  /*height: 100%!important;
  overflow: auto!important;*/
}
</style>
