
<template>
  <div class="container-app">
    <div class="systemLogo">
      <div class="title">{{title}}</div>
      <div class="time">{{currentTime}}</div>
      <div class="switch">
        <div class="link"><router-link to="/DeviceBoard">设备看板</router-link></div>
        <div class="link"><router-link to="/MapBoard">地图看板</router-link></div>
      </div>
    </div>
    <div class="main row">
      <div class="col-9 left">
        <div class="row">
          <div class="col-4 cards leftCard">
            <div class="light-card leftChildCard">
              <LightBox />
            </div>
            <div class="light-card leftChildCard">
              <EnvironMonitor />
            </div>
          </div>
          <div class="col-8 cards leftCard">
            <div class="map-card">
              <Map />
            </div>
          </div>
          <div class="cards">
            <div class="light-card">
              <LightBoxStatus />
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 right">
        <div class="chartscards">
          <div class="light-card voltagestatuspa">
            <VoltageStatus />
          </div>
          <div class="light-card currentstatepa">
            <CurrentState />
          </div>
          <div class="light-card temperatureandhumiditypa">
            <TemperaTureandHumidity />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LightBox from '../../views/lightBox/lightBox.vue'
import EnvironMonitor from '../../views/environmonitor/index.vue'
import VoltageStatus from '../../views/voltagestatus/index.vue'
import CurrentState from '../../views/currentstate/index.vue'
import TemperaTureandHumidity from '../../views/temperatureandhumidity/temperatureandhumidity.vue'
import LightBoxStatus from '../../views/lightboxstatus/lightboxstatus.vue'
import Map from '../../views/map/index.vue'
// import API from "../../plugins/axiosInstance"
export default {
  data () {
    return {
      title: 'AIoT智能景观灯远程控制平台',
      currentTime:'',
      listData:[]
    }
  },
  components: {
    LightBox,
    EnvironMonitor,
    VoltageStatus,
    CurrentState,
    TemperaTureandHumidity,
    LightBoxStatus,
    Map
  },
  methods:{
    getCurrentTime(){
      const now = new Date()
      this.currentTime = now.toLocaleString().replace(/\//g,"-")
    },
    getToken(){
      console.log(localStorage.getItem("token"))
      if(!localStorage.getItem("token") || localStorage.getItem("token") === '' || localStorage.getItem("token") === null){
        this.$router.push('/login')
      }
    },
   
  },
  mounted(){
    let that = this
    that.getToken()
    setInterval(() => {
      that.getCurrentTime();
      //console.log(new Date() < new Date(localStorage.getItem("expiretime")))
      if(new Date() > new Date(localStorage.getItem("expiretime"))){
        localStorage.setItem("token", '')
        that.$router.push('/login')
      }
    },1000)
    // setInterval(() => {
    //   this.getLightBoxData()
    // },5000)
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.time {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 20px;
}
.container-app {
  min-width: 1900px;
  width: auto;
  height: 1080px;
}
.systemLogo {
  width:100%;
  height:70px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  background:rgba(3, 3, 34, 0.8);
  margin-bottom:15px;
}
.systemLogo>div {
  padding: 0 60px;
  color:#fff;
}
.title {
  height:70px;
  line-height:70px;
  font-size: 20px;
  /* background:#222 url(../../assets/logo.png) left center no-repeat; */
  background-size: 60px 60px;
}
.switch {
  display:flex;
}
.link {
  display:block;
  padding:5px 10px;
  line-height: 30px;
  border:1px solid #fff;
  margin: 0 5px;
  border-radius:5px;
}
a {
  display:block;
  width:4em;
  line-height: 30px;
  color:#fff;
  text-decoration:none;
}
.main {
  width: calc(100% - 10px);
  margin: 0 auto;
  position: relative;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:baseline;
}
.light-card {
  width: 100%;
  background:rgba(3, 3, 34, 0.8);
  border-radius: 5px;
  height: 305px;
  margin: 0 0 24px 0;
}
.map-card {
  width: 100%;
  background:rgba(3, 3, 34, 0.8);
  border-radius: 5px;
  height: 634px;
}
.leftCard {
  }
@media screen and (max-width:1600px) {
  .container-app {
    width: 1600px;
    height: inherit;
    background-size:cover
  }
  .light-card {
    height: 245px;
  }
  .map-card {
    height: 514px;
  }
}

@media screen and (max-width:1440px) {
  .container-app {
    min-width: inherit;
    width: 100%;
    height: inherit;
    background-size:cover
  }
  .light-card {
    height: 245px;
  }
  .map-card {
    height: 514px;
  }
}
@media screen and (max-width:1200px) {
  .container-app {
    min-width: inherit;
    width: 100%;
    height: inherit;
    background-size:cover;
  }
  
  .main {
    position: relative;
  }
  .left {
    position: absolute;
    left:0;
    top: 269px;
    width: 100%!important;
    z-index: 10;
  }
  .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 245px;
    z-index: 10;
    overflow: hidden;
  }
  
  .chartscards {
    display: flex;
    flex-direction: row;
    width: calc(100% + 24px);
    margin-left: -12px;
  }
  .right>div>div {
    width: calc(100%/3 - 24px);
    margin: 0 12px;
  }
  .light-card {
    height: 245px;
  }
  .map-card {
    height: 514px;
  }
  /*.voltagestatuspa {
    position: absolute;
    left: 20px;
    top: 85px;
    width: 30%;
  }
  .currentstatepa {
    position: absolute;
    left: calc(30% + 50px);
    top: 85px;
    width: 30%;
  }
  .temperatureandhumiditypa {
    position: absolute;
    left: calc(60% + 80px);
    top: 85px;
    width: 30%;
  }*/
}

@media screen and (max-width:820px) {
  .leftCard {
    width: 100%!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  .leftChildCard {
    margin: 0 12px;
  }
}

@media screen and (max-width:414px) {
  .systemLogo {
    display: block;
    height: inherit;
    padding-bottom: 12px;
  }
  .systemLogo>div {
    padding: 12px 15px!important;
  }
  .right {
    top: 0;
    left: 0;
    width: 100%!important;
    height: 807px;
    z-index: 10;
    overflow: hidden;
  }
  .chartscards {
    display: block;
  }
  .right>div>div {
    width:calc(100% - 24px);
    margin: 0 12px 24px 12px;
  }
  .left {
    position: absolute;
    left:0;
    top: 807px;
    width: 100%!important;
    z-index: 10;
  }
  .leftCard {
    width:calc(100% - 24px);
    display: block;
    margin: 0 0 0 0;
  }
  .leftChildCard {
    margin: 0 0 24px 0;
  }
  .map-card {
    height: 250px;
    margin: 0 0 24px 0;
  }
}
</style>
../lightbox/lightbox.vue../environmonitor/index.vue../voltagestatus/index.vue../currentstate/index.vue../temperatureandhumidity/temperatureandhumidity.vue../lightboxstatus/lightboxstatus.vue../map/index.vue