<template>
  <div class="devicelist-box">
		<div class="title">
      <div>
        <ul>
          <li><span class="devicestatus-1"></span>运行<div>{{ deviceInfo.run }}</div></li>
          <li><span class="devicestatus-2"></span>检修<div>{{ deviceInfo.check }}</div></li>
          <li><span class="devicestatus-3"></span>离线<div>{{ deviceInfo.outline }}</div></li>
          <li><span class="devicestatus-4"></span>未启用<div>{{ deviceInfo.unable }}</div></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>本页数量：<div>{{ deviceInfo.baselen }} 台</div></li>
          <li>总数量：<div>{{deviceInfo.len}} 台</div></li>
        </ul>
      </div>
    </div>
		<div class="row drow">
      <div class="col-2" v-for="(item, index) in device1" :key="index">
        <div class="dcell">
          <div class="runningState">
            <div class="info">
              <span v-if="item.status == 0" class="outline"></span>
              <span v-if="item.status == 1" class="run"></span>
              <span v-if="item.status == 2" class="check"></span>
              <span v-if="item.status == 3" class="unable"></span>
              <div>
                {{item.groupName}} <br />
                {{item.deviceName}}
              </div>
            </div>
            <div class="switch">
              <div class="switchBtn" @click="switchLight(index,item.deviceName)">
                <div v-if="item.nightLightSwitch == '0'" :class="{'off':item.nightLightSwitch == '0'}"></div>
                <div v-if="item.nightLightSwitch == null" :class="{'off':item.nightLightSwitch == null}"></div>
                <div :class="{'on':item.nightLightSwitch == '1'}"></div>
              </div>              
            </div>
          </div>
          <div class="msg">
            <span>电压：</span>
            <span>{{item.voltagePhaseA != null ? item.voltagePhaseA:'0'}}</span>
            <span>{{item.voltagePhaseB != null ? item.voltagePhaseB:'0'}}</span>
            <span>{{item.voltagePhaseC != null ? item.voltagePhaseC:'0'}}</span>
          </div>
          <div class="msg">
            <span>电流：</span>
            <span>{{item.currentPhaseA != null ? item.currentPhaseA:'0'}}</span>
            <span>{{item.currentPhaseB != null ? item.currentPhaseB:'0'}}</span>
            <span>{{item.currentPhaseC != null ? item.currentPhaseC:'0'}}</span>
          </div>
          <div class="cell-bottom"></div>
        </div>
      </div>
		</div>
  </div>
</template>

<script>
import API from "../../plugins/axiosInstance"
export default {
  data () {
    return {
      device1:[],
      deviceInfo:{
        len:0,
        baselen:0,
        run:0,
        check:0,
        outline:0,
        unable:0,
        state:"0"
      }
    }
  },
	methods:{
    switchLight(key,item){
      const that = this
      const token = "token "+localStorage.getItem("token")
      if(that.device1[key].nightLightSwitch == 0){
        that.device1[key].nightLightSwitch = 1
      } else {
        that.device1[key].nightLightSwitch = 0
      }
      API({
        url:'/api/v1/front-end/SetDeviceDetails',
        method:'POST',
        data:{
          'device':item,
          'status':that.device1[key].nightLightSwitch
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        console.log(res)
      })
    },
    getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        that.device1 = res.data.filter(item => item.page == 1)
        that.device2 = res.data.filter(item => item.page == 2)
        that.device3 = res.data.filter(item => item.page == 3)
        that.device4 = res.data.filter(item => item.page == 4)
        console.log(that.device1)
        that.deviceInfo.len = res.data.length
        that.deviceInfo.baselen = that.device1.length
        that.deviceInfo.outline = that.device1.filter(item => item.status == 0).length
        that.deviceInfo.run = that.device1.filter(item => item.status == 1).length
        that.deviceInfo.check = that.device1.filter(item => item.status == 2).length
        that.deviceInfo.unable = that.device1.filter(item => item.status == 3).length
      });
    }
  },
  mounted(){
    //this.getData()
    this.getLightBoxData()
    setInterval(() => {
      this.getLightBoxData()
    },5000)
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.devicelist-box {
	width: 100%;
	height: 100%;
  background:rgba(3, 3, 34, 0.8);
	border-radius: 5px;
}
div {
  font-family: Georgia, '微软雅黑', 'Times New Roman', Times, serif;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.devicelist-box {
  padding-bottom: 15px;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 10px 0 10px;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
	justify-content: space-between;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  height: 16px;
  width: 6px;
  border-radius: 3px;
  margin-right: 10px;
}
.title>div>ul>li div {
	margin:0 15px;
	font-size: 20px;
}
.devicestatus-1 {
  background-color: #9ce77e;
}
.devicestatus-2 {
  background-color: #0f6fc5;
}
.devicestatus-3 {
  background-color: #fb594c;
}
.devicestatus-4 {
  background-color: #717277;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.drow {
  width: calc(100% - 30px);
  padding-top: 10px;
  padding-bottom: 15px;
  margin: 0 auto 15px auto;
}
.drow>div {
  margin-bottom: 15px;
}
.dcell {
  height: 200px;
  background:rgba(0, 0, 0, 0.9);
  position: relative;
  border-radius: 7px;
}
.runningState {
  display: flex;
  padding: 15px 15px 0 15px;
  color: #fff;
  justify-content: space-between;
}

.runningState span {
  width: 12px;
  height: 36px;
  display: block;
  border-radius: 6px;
  margin-right: 10px;
}
.runningState span.run {
  background-color: #95f077;
}
.runningState span.check {
  background-color: #0f6fc5;
}
.runningState span.outline {
  background-color: #fb594c;
}
.runningState span.unable {
  background-color: #717277;
}
.runningState div {
  line-height: 20px;
  font-size: 12px;
}
.msg {
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.msg span {
  display: block;
  width: 25%;
  text-align: center;
}
.cell-bottom {
  position: absolute;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  bottom: 0;
  left: 0;
  background-color: #3d4051;
}
.info {
  margin: 0 15px 0 0;
  display: flex;
  flex-direction: row;
}
.switchBtn {
  width: 40px;
  height: 20px;
  background-color: #c2c2c2;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  cursor:pointer;
}
.switchBtn>div {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #c2c2c2;
}
.on {
  background-color: rgb(17, 145, 17);
}
.off {
  background-color: #6e6e6e;
}
@media screen and (max-width:1600px) {
  .dcell{
    height:inherit
  }
  .cell-bottom {
    position:inherit;
    width: 100%;
    height: 30px;
    border-radius: 7px;
  }
  .devicelist-box {
    height:inherit;
  }
  .col-2 {
    width:20%;
  }
  .msg {
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    padding: 5px 15px 5px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width:1440px) {
  .col-2 {
    width:200px;
  }

}
@media screen and (max-width:1200px) {
  .col-2 {
    width:200px;
  }
}

@media screen and (max-width:1024px) {
  .title {
    display: block;
  }
  .col-2 {
    width:25%;
  }
}
@media screen and (max-width:820px) {
  
  .title {
    display: block;
  }
  .col-2 {
    width:33.33333%;
  }
}

@media screen and (max-width:414px) {
  .title{
    font-size:14px
  }
  .title>div>ul>li {
    margin: 0 5px
  }
  .title>div>ul>li div{
    font-size:14px;
    margin:0 5px
  }
  .col-2 {
    width:100%;
  }
}
</style>
