import { createRouter, createWebHistory } from "vue-router";
import LoginPage from '../views/login/index.vue'
import HomeView from '../views/home/index.vue'
import DeviceBoard from '../views/deviceboard/index.vue'
import MapBoard from '../views/mapboard/index.vue'

// vue项目自带路由

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: '/',
    name: 'loginpage',
    meta: {
      title: '智能景观灯控制'
    },
    component: LoginPage
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '智能景观灯控制'
    },
    component: LoginPage
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: HomeView
  },
  {
    path: '/deviceboard',
    name: 'deviceboard',
    meta: {
      title: '设备看板'
    },
    component: DeviceBoard
  },
  {
    path: '/mapboard',
    name: 'mapboard',
    meta: {
      title: '地图看板'
    },
    component: MapBoard
  }]
});
// const originalPush = router.prototype.push;
// router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// }
export default router;

