
<template>
  <div class="container-app">
    <div class="systemLogo">
      <div class="title">{{title}}</div>
      <div class="time">{{currentTime}}</div>
      <div class="switch">
        <div class="link"><router-link to="/home">返回首页</router-link></div>
        <div class="link"><router-link to="/DeviceBoard">设备看板</router-link></div>
      </div>
    </div>
    <div id="map"></div>
    <!-- <div class="info">
      <h4>获取地图级别与中心点坐标</h4>
        <p>当前级别：<span id="map-zoom">11</span></p>
        <p>当前中心点：<span id="map-center">121.498586,31.239637</span></p>
    </div> -->
    <!-- <div class="input-card">
        <h4>鼠标左键获取经纬度：</h4>
        <div class="input-item">
          <input type="text" readonly="true" id="lnglat">
        </div>
    </div> -->
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader' // 使用加载器加载JSAPI，可以避免异步加载、重复加载等常见错误加载错误
// import { shallowRef } from '@vue/reactivity'
// import { onMounted } from '@vue/runtime-core'
// const map = shallowRef(null);

export default {
  data () {
    return {
      title: 'AIoT智能景观灯远程控制平台',
      currentTime:'',
      positionData:[]
    }
  },
  methods:{
    getCurrentTime(){
      const now = new Date()
      this.currentTime = now.toLocaleString().replace(/\//g,"-")
    },
    initMap() {
      AMapLoader.load({
        key: "ff909f7986d4bfaafcbba59b5ea59550", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale", //工具条，控制地图的缩放、平移等
          "AMap.ToolBar", //比例尺，显示当前地图中心的比例尺
          //"AMap.Geolocation", //定位，提供了获取用户当前准确位置、所在城市的方法
          //"AMap.HawkEye", //鹰眼，显示缩略图
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          let map = new AMap.Map("map", {//设置地图容器id
            zoom: 16, //初始化地图层级
            viewMode: "3D", //是否为3D地图模式
            center: [121.124541,31.15049], //初始化地图中心点位置
            dragEnable: true, //禁止鼠标拖拽
            scrollWheel: true, //鼠标滚轮放大缩小
            doubleClickZoom: true, //双击放大缩小
            keyboardEnable: true, //键盘控制放大缩小移动旋转
            mapStyle:'amap://styles/dark',
          });
          map.setDefaultCursor("pointer"); //使用CSS默认样式定义地图上的鼠标样式（default/pointer/move/crosshair）
          map.addControl(new AMap.Scale()); //异步同时加载多个插件
          map.addControl(new AMap.ToolBar());
          //map.addControl(new AMap.Geolocation());
          // let HawkEye = new AMap.HawkEye({
          //   position: "LT", //控件停靠位置（LT/RT/LB/RB）
          // });
          //map.addControl(HawkEye);
          map.add(
            new AMap.Marker({
              position: map.getCenter(),
            })
          );
          // map.add(marker); // 地图添加标记
          AMapLoader.load({
            //可多次调用load
            plugins: ["AMap.MapType"],
          })
            .then((AMap) => {
              map.addControl(new AMap.MapType());
            })
            .catch((e) => {
              console.error(e);
            });
          // 显示地图层级与中心点信息
          // let logMapinfo = function() {
          //   let zoom = map.getZoom(); //获取当前地图级别
          //   let center = map.getCenter(); //获取当前地图中心位置
          //   document.querySelector("#map-zoom").innerText = zoom;
          //   document.querySelector("#map-center").innerText = center.toString();
          // }
          //绑定地图移动与缩放事件
          // map.on("moveend", logMapinfo);
          // map.on("zoomend", logMapinfo);
          //为地图注册click事件获取鼠标点击出的经纬度坐标
          // map.on("click", function (e) {
          //   document.getElementById("lnglat").value =
          //     e.lnglat.getLng() + "," + e.lnglat.getLat();
          // });
          // let infoWindow = new AMap.InfoWindow({
          //   //创建信息窗体
          //   isCustom: false, //使用自定义窗体
          //   anchor: "top-right", //信息窗体的三角所在位置
          //   content: `<h4>信息窗体</h4>`, //信息窗体的内容可以是任意html片段
          //   offset: new AMap.Pixel(16, -45),
          // });
          //infoWindow.open(map, [121.124541,31.15049]); //填写想要窗体信息指示的坐标
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // mapPoint(){
    //   var _this = this;
    //   /* 1.创建 LabelMarker，
    //   2.创建 LabelsLayer，将已创建的 LabelMarker 添加到 LabelsLayer 上
    //   3. 将 LabelsLayer 添加到地图实例 */
    //   var labelsLayer = new AMap.LabelsLayer({
    //       zooms: [3, 20],
    //       zIndex: 1000,
    //       // 该层内标注是否避让
    //       collision: true,
    //       // 设置 allowCollision：true，可以让标注避让用户的标注
    //       allowCollision: true,
    //   });
    //   //将 LabelsLayer 添加到地图实例
    //   _this.map.add(labelsLayer);
      
 
    //   _this.infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -4)});//信息窗口
    //   var position = JSON.parse(JSON.stringify(_this.positionData));
    //   for(var i = 0; i < position.length; i++){
    //     var labelMarker = new AMap.LabelMarker({
    //         position: position[i].positionArr
    //     });
        
    //     //标注信息
    //     var normalMarker = new AMap.Marker({ 
    //       position: position[i].positionArr, 
    //       map: _this.map,
    //       icon: ''//require("@/assets/imgs/icons/"+ position[i].onlineImg)
    //     });
    //     normalMarker.content = '<p><b>序号1：</b>'+position[i].deviceMarkId+'</p>'+
    //                           '<p><b>序号2：</b>'+position[i].sn+'</p>'+
    //                           '<p><b>地址：</b>'+position[i].address+'</p>'+
    //                           '<strong style="color:#090;cursor:pointer;" onclick="handleDetail(\''+position[i].deviceMarkId+'\')">查看详情</strong>'
    //     normalMarker.on('click', _this.markerClick);
    //     normalMarker.setExtData({deviceMarkId: position[i].deviceMarkId}); //给标注添加信息，点击时可以获取
    //   }
    //   // 一次性将海量点添加到图层
    //   labelsLayer.add(labelMarker);
    //   this.loading = false; //地图加载完成
    // },
    markerClick(e) {
      //这个只是标注的点击事件
        // this.map.setZoomAndCenter(8,e.target.getPosition()); //点击标点放大地图-缺点，不管放大/缩小多少倍，点击标点都会被缩放到8倍
        this.infoWindow.setContent(e.target.content);
        this.infoWindow.open(this.map, e.target.getPosition());
    },
    getToken(){
      console.log(localStorage.getItem("token"))
      if(!localStorage.getItem("token") || localStorage.getItem("token") === '' || localStorage.getItem("token") === null){
        this.$router.push('/login')
      }
    },
  },
  mounted(){
    let that = this
    that.getToken()
    setInterval(() => {
      that.getCurrentTime();
      if(new Date() > new Date(localStorage.getItem("expiretime"))){
        localStorage.setItem("token", '')
        that.$router.push('/login')
      }
    },1000);
    that.initMap();
  },
  created(){
    //标注文字上的点击事件
    window.handleDetail = (deviceMarkId)=>{
        this.$router.push({path:"/device/nowMonitor", query:{deviceMarkId}})
      }
  },
  unmounted(){
    // 销毁地图，并清空地图容器.该方法执行后，地图对象被注销，内存释放，地图容器被清空。
    //this.map.destroy( );
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.time {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 20px;
}
.container-app {
  width: auto;
  height: 1080px;
  overflow: hidden;
  position: relative;
}
.systemLogo {
  width:100vw;
  height:70px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  background:#222;
  margin-bottom:15px;
}
.systemLogo>div {
  padding: 0 60px;
  color:#fff;
}
.title {
  height:70px;
  line-height:70px;
  font-size: 20px;
  /* background:#222 url(../../assets/logo.png) left center no-repeat; */
  background-size: 60px 60px;
}
.switch {
  display:flex;
}
.link {
  display:block;
  padding:5px 10px;
  line-height: 30px;
  border:1px solid #fff;
  margin: 0 5px;
  border-radius:5px;
}
a {
  display:block;
  width:4em;
  line-height: 30px;
  color:#fff;
  text-decoration:none;
}
.main {
  width: calc(100% - 10px);
  margin: 0 auto;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:baseline;
}
#map { width: 100%; height: calc(100% - 85px);}
.info { position: absolute; right: 50px; bottom: 50px;}

@media screen and (max-width:1600px) {
  .container-app {
    width: 100%;
    height: 100vh;
    background-size:cover
  }
  .systemLogo>div {
    padding: 0 15px 0 15px!important;
  }
}
@media screen and (max-width:414px) {
  .systemLogo {
    display: block;
    height: 192px;
    padding-bottom: 12px;
  }
  .systemLogo>div {
    padding: 12px 15px!important;
  }
}
</style>
