<template>
  <div class="container-list">
		<div class="title">
      <div>{{title}}</div>
    </div>
		<div class="row tablerow">
			<el-table :data="lightBoxData" :row-style="{'border-bottom': 'none'}" :cell-style="tableRowClassName" highlight-current-row :key="tableKey" :header-cell-style="{ 'background': '#000', 'color': '#fff', 'text-align': 'center', 'height':'50px'}">
>
				<el-table-column prop="deviceName" label="设备名称"></el-table-column>
				<el-table-column prop="currentPhaseA" label="A相电流"></el-table-column>
				<el-table-column prop="currentPhaseB" label="B相电流"></el-table-column>
				<el-table-column prop="currentPhaseC" label="C相电流"></el-table-column>
				<el-table-column prop="voltagePhaseA" label="A相电压"></el-table-column>
				<el-table-column prop="voltagePhaseB" label="B相电压"></el-table-column>
				<el-table-column prop="voltagePhaseC" label="C相电压"></el-table-column>
			</el-table>
		</div>
  </div>
</template>

<script>
// import echarts from 'echarts'
import API from "../../plugins/axiosInstance"
export default {
  name: 'lightBox',
  data () {
    return {
      title: '景观灯箱状态',
			tableKey:1,
			headList:['设备名称','A相电流','B相电流','C相电流','A相电压','B相电压','C相电压'],
			colprops:["deviceName","currentPhaseA","currentPhaseB","currentPhaseC","voltagePhaseA","voltagePhaseB","voltagePhaseC"],
			lightBoxData:[]    
    }
  },
	mounted(){
    this.getLightBoxData()
  },
  methods: {
		tableRowClassName({ rowIndex }) {
			let row1 =  { 'background': '#000', 'color': '#fff', 'text-align': 'center', 'border-bottom': 'none'}
			let row2 =  { 'background': '#222', 'color': '#fff', 'text-align': 'center', 'border-bottom': 'none'}
      if (rowIndex % 2 === 1) {
        return row1;
      } else {
        return row2
      }
    },
     
    getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        that.lightBoxData = res.data
      });
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tablerow {
	width: calc(100% - 30px)!important;
	padding: 0!important;
	margin: 0 auto;
}
.el-table {
	width: 100%;
	margin: 0!important;
	padding: 0!important;
	color: #fff;
	border:1px solid #000;
  height: 200px;
}
.container-list {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #8572e5;
}
.status-2 {
  background-color: #fff;
}
.status-3 {
  background-color: #5bbda6;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
/* 去除底部的白线 */
.el-table::before {
    height: 0;
}
:deep .el-table .el-table__body td .cell:empty::after{
  content: "0";
}
@media screen and (max-width:1600px) {
  .el-table {
    height: 175px;
  }
}
</style>
