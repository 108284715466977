import './assets/main.css'

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import { createPinia } from 'pinia'
import Echarts from './js/echarts.js'
import VueWechatTitle from 'vue-wechat-title'

import App from './App.vue'
import router from './router'
import axios from '@/plugins/axiosInstance.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
const app = createApp(App)
app.config.globalProperties.$echarts = Echarts
app.config.globalProperties.$axios=axios;
app.use(VueWechatTitle)
app.use(ElementPlus)
app.use(createPinia())
app.use(router)
app.mount('#app')
