<template>
  <div class="container-app">
    <div class="loginFrame">
      <div class="logo">上海推晨智能电子科技有限公司</div>
      <div class="login">
        <ul>
          <!-- <li>
            <span>ID：</span><input v-model="systemId" placeholder="请输入外部集控系统ID！" />
          </li> -->
          <li>
            <span>用户名：</span><input v-model="sysname" placeholder="请输入外部集控系统名称！" />
          </li>
          <li>
            <span>密码：</span><input v-model="password" type="password" placeholder="请输入密码！" />
          </li>
          <li>
            <button type="submit" @click="login">登录</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../plugins/axiosInstance"
import { ElMessage } from "element-plus";

export default {
  data(){
    return {
      sysname:'',
      password:'',
      systemId:'frontend',
    }
  },
  methods:{
    login(){
      let params = {}
      params.sysId = this.systemId
      params.sysname = this.sysname
      params.Password = this.password
      if(params.sysId == ""){
        ElMessage.error('请输入外部集控系统ID！')
      } else if (params.sysname == "") {
        ElMessage.error('请输入外部集控系统名称！')
      } else if (params.Password == "") {
        ElMessage.error('请输入密码！')
      } else {
        API({
          url:'/api/v1/DoLogin?sysId='+ params.sysId+'&sysname='+params.sysname+'&Password='+params.Password,
          method:'POST',
          headers:{
            'Content-type': 'application/x-www-form-urlencoded'
          },
        }).then((res)=>{
          if(res.status == 200){
            console.log(res)
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("expiretime", res.data.expirationDate);
            console.log(localStorage.getItem("expiretime"))
            this.$router.push('/home')
          }
        }).catch(err => {
          console.log(err)
          ElMessage.error('登录失败!')
        });
      }
    }
  },
  mounted(){
    localStorage.setItem("token", '')
    // let that = this
    // if(localStorage.getItem("token") != '' || localStorage.getItem("token") != null){
    //   that.$router.push('/home')
    // }
  }
}
</script>

<style scoped>
ul,li {
  margin:0;
  padding:0;
  list-style:none
}
.container-app {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
}
.loginFrame {
  width:500px;
  background: rgba(255,255,255,.8);
  box-shadow:5px 5px 5px rgba(0,0,0,.5);
  border-radius:8px;
  border:1px solid rgba(0,0,0,.5);
  padding:30px;
}
.logo {
  width:100%;
  /* height:100px;
  background:url(../../assets/logo.svg) center center no-repeat;
  background-size:100%; */
  color:#222;
  margin:0 auto;
  line-height:3em;
  font-size:20px;
  font-weight:700;
  text-align:center;
  margin-bottom:30px
}
.loginFrame ul {
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction: column;
}
.loginFrame ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:15px;
  color: #222;
}
.loginFrame ul li span {
  display: block;
  width: 4em;
}
button {
  background-color: #1c578f;
  color: #fff;
  padding: 4px 16px;
}
input {
  padding: 5px;
}
input:focus {
  outline: none;
}
@media screen and (max-width:1600px) {
  .container-app {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width:500px) {
  .container-app {
    width: 100%;
  }
  .loginFrame {
    width:calc(100% - 30px);
  }
  .logo {
    width:100%;
    /* height:100px;
    background:url(../../assets/logo.svg) center center no-repeat;
    background-size:100%; */
    color:#222;
    line-height:1.75em;
    font-size:20px;
    font-weight:700;
    text-align:center;
    margin-bottom:30px
  }
  .loginFrame ul li {
    width: 100%;
  }
  button {
    width: 100%;
    line-height: 2.2em;
    background-color: #1c578f;
    border: 0;
    margin-top: 10px;
  }
  input {
    width:calc(100% - 4em - 10px);
  }
}
</style>